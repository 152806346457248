<template>
  <div style="display: flex;">
    <div style="flex: 1;">
      <el-select v-model="tmpAttribute.name" :value-key="'id'"
                 allow-create filterable :placeholder="KT('attribute.attribute')" :size="'large'"
                 :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
        <el-option
          v-for="item in availableAttributes"
          :key="item"
          :label="$t('attribute.' + item)"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div style="flex: 1; margin-left: 5px;">
      <el-input v-model="tmpAttribute.value" :placeholder="KT('attribute.value')"></el-input>
    </div>
    <div style="padding: 4px;">
      <el-button type="primary" @click="addAttribute()" size="small">{{ KT('attribute.add') }}</el-button>
    </div>
  </div>

  <div style="border: silver 1px solid; border-radius: 5px; margin-top: 5px; margin-bottom: 15px; height: calc(70vh - 200px); overflow-y: auto;">
    <div v-for="(a, k) in attributesFiltered" :key="k" style="display: flex; border-bottom: silver 1px dotted;">
      <div style="padding: 7px; flex: 1;">{{$t('attribute.' + k)}}</div>
      <div style="padding: 2px; flex: 1;">
        <input style="width: 100%; border: silver 1px solid; height: 23px; border-radius: 5px; padding: 2px;" @input="changeAttribute(k, $event)" :value="a">
      </div>
      <div style="padding: 5px; padding-right: 10px; font-size: 18px;" @click="remove(k)">
        <i class="fas fa-minus-square"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits, ref } from 'vue';
import { ElSelect, ElOption, ElButton, ElInput } from 'element-plus';

// Datos predeterminados para los atributos disponibles
const defaultAvailableAttributes = {
  device: ['speedLimit', 'currency', 'lockOnExit', 'lockOnEnter'],
  geofence: ['speedLimit', 'lockOnExit', 'lockOnEnter', 'isAnchor'],
  user: ['telegramChatId', 'facturacionActiva'],
  server: ['volumeUnit', 'distanceUnit', 'speedUnit', 'telegramBot', 'timezone', 'driverUniqueId']
};

// Estado temporal para el atributo que se está agregando
const tmpAttribute = ref({ name: '', value: '' });

// Props del componente
const props = defineProps(['modelValue', 'type']);
const emit = defineEmits(['update:modelValue']);

// Atributos disponibles basados en el tipo
const _availableAttributes = ref(defaultAvailableAttributes);
const availableAttributes = computed(() => {
  return _availableAttributes.value[props.type] || [];
});

// Función para cambiar un atributo existente
const changeAttribute = (key, event) => {
  const newValue = event.target.value;
  const updatedAttributes = { ...props.modelValue };
  updatedAttributes[key] = newValue;
  emit('update:modelValue', updatedAttributes);
};

// Filtrar los atributos para excluir los que no deben ser mostrados
const attributesFiltered = computed(() => {
  const filteredAttributes = { ...props.modelValue };
  Object.keys(filteredAttributes).forEach(key => {
    if (key.match('tarkan.')) {
      delete filteredAttributes[key];
    }
  });
  return filteredAttributes;
});

// Agregar un nuevo atributo a la lista
const addAttribute = () => {
  if (tmpAttribute.value.name && tmpAttribute.value.value) {
    const updatedAttributes = { ...props.modelValue };
    updatedAttributes[tmpAttribute.value.name] = tmpAttribute.value.value;
    emit('update:modelValue', updatedAttributes);
    tmpAttribute.value = { name: '', value: '' }; // Clear after adding
  }
};

// Eliminar un atributo de la lista
const remove = (key) => {
  const updatedAttributes = { ...props.modelValue };
  delete updatedAttributes[key];
  emit('update:modelValue', updatedAttributes);
};
</script>

<style scoped>
/* Estilo para la grilla de atributos */
.el-select {
  width: 100%;
}

.el-input {
  width: 100%;
}

.el-button {
  margin-left: 10px;
}

div[style*="border: silver 1px solid;"] {
  overflow-y: auto;
}

div[style*="border: silver 1px dotted;"] {
  display: flex;
  border-bottom: silver 1px dotted;
}

input {
  width: 100%;
  border: silver 1px solid;
  height: 23px;
  border-radius: 5px;
  padding: 2px;
}
</style>
